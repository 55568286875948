import React from 'react';
import testimonialImageSrc from '../images/testimonial-author-lingoreaders.png'

export const TestimonialsSection = () => {
  return (
      <section className="container mx-auto lg:flex px-8 pb-12 pt-2">
        <div className="lg:w-2/3 mx-auto">
          <h3 className="text-3xl font-semibold leading-tight mb-3">Is it for me?</h3>
          <img className="rounded-image" style={{ width: "100%" }} src={testimonialImageSrc} />
          <p className='pt-2'>...and you can sign up for it <a href='https://cutt.ly/lingoreaders-website'>👉 here 👈</a></p>
        </div>
      </section>
  )
}
