import React from 'react';

const accordionToggle = (argu) => {
  console.log(argu.target)
  console.log('target id', argu.target.attributes.id)
  const bodyId = argu.target?.attributes?.['data-accordion-target']?.value;
  // console.log('bodyId', bodyId);
  
  const bodyElement = document.querySelector(bodyId);
  console.log('bodyElement', bodyElement);

  const isHiddenClassSet = [...bodyElement.classList].find((a) => a ==='hidden');

  if (isHiddenClassSet) {
    bodyElement.classList.remove('hidden')
  } else {
    bodyElement.classList.add('hidden')
  }
}

const AccordionTitle = ({ children, number }) => (
  <button
    id={`accordion-collapse-heading-${number}`}
    type="button"
    onClick={accordionToggle}
    className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
    data-accordion-target={`#accordion-collapse-body-${number}`}
    aria-expanded="true"
    aria-controls={`#accordion-collapse-body-${number}`} >
      {children}
      <svg data-accordion-icon className="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
      </svg>
  </button>
)

const AccordionBody = ({ children, number }) => (
  <div id={`accordion-collapse-body-${number}`} className="hidden" aria-labelledby={`accordion-collapse-heading-${number}`}>
    <div className="accordion-content p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
{children}
    </div>
  </div>
)

const Accordion = ({ className, children }) => (
  <div id="accordion-collapse" data-accordion="collapse">
    <AccordionTitle number="1">
    Section 1, Unit 5 (Short)
    </AccordionTitle>
    <AccordionBody number="1">
      Ana es una niña. Ella bebe leche y come una manzana. Juan es un niño. Él bebe agua y come pan. Ana y Juan juegan en el parque después de comer.
    </AccordionBody>

    <AccordionTitle number="2">
    Section 1, Unit 5 (Long)
    </AccordionTitle>
    <AccordionBody number="2">
      <p>Ana y Juan son hermanos. Ellos viven en una casa en Madrid. Un día, Ana y Juan van al supermercado con su mamá. Ana necesita comprar manzanas y pan. Juan quiere un vaso de leche. La mamá compra una ensalada de tomate y queso. En el supermercado, Ana dice, "Necesito agua, por favor." Juan responde, "Yo también."</p>

      <p>Después de comprar, ellos van al museo. El museo está en una calle grande y muy interesante. Ana y Juan ven una bicicleta antigua y un carro viejo. "¡Qué interesante!" dice Ana. "Sí," responde Juan, "es muy bonito."</p>

      <p>Luego, ellos vuelven a casa. La mamá prepara una hamburguesa para Juan y una ensalada para Ana. En la noche, todos dicen, "Buenas noches." Ana y Juan están muy contentos por el día.</p>
    </AccordionBody>

    <AccordionTitle number="3">
      Section 1, Unit 6 (Short)
    </AccordionTitle>
    <AccordionBody number="3">
      <p>Hola, soy Juan y voy al banco con mi hermana Ana. Necesito dinero para pagar mi boleto de tren. El banco está cerrado, así que esperamos. Mientras esperamos, Ana bebe agua y yo como una manzana. Después, decimos "adiós" y vamos a casa.</p>
    </AccordionBody>

    <AccordionTitle number="4">
      Section 1, Unit 6 (Long)
    </AccordionTitle>
    <AccordionBody number="4">
      <p>Ana y Juan son niños. Ellos viven en Madrid. Un día, Ana y Juan van al museo con su papá y su mamá. En el museo, ven una bicicleta grande y un carro verde. Ana dice, "¡Qué interesante!"</p>

      <p>Después, ellos van al restaurante. Juan quiere una hamburguesa y Ana quiere una ensalada con tomate. La mamá bebe un vaso de jugo de naranja y el papá bebe agua.</p>

      <p>Luego, ellos caminan a la calle y ven un banco. Juan dice, "Necesito dinero para comprar una manzana en el supermercado." Ana dice, "Yo quiero un sombrero azul, por favor."</p>

      <p>En la noche, ellos vuelven a casa. La mamá dice, "Buenas noches, niños." Ana y Juan responden, "Buenas noches, mamá. Gracias por el día.”</p>
    </AccordionBody>
    <AccordionTitle number="5">
      Section 1, Unit 7 (Short)
    </AccordionTitle>
    <AccordionBody number="5">
      <p>Juan y Ana van al supermercado y compran manzanas, leche y pan. Luego, ellos van al parque y juegan con su perro y gato. Después, beben agua y comen una hamburguesa. Por la noche, dicen "buenas noches" y se van a dormir.</p>
    </AccordionBody>

    <AccordionTitle number="6">
      Section 1, Unit 7 (Long)
    </AccordionTitle>
    <AccordionBody number="6">
      <p>Hola, soy Ana y tengo un hermano llamado Juan. Un día, Juan y yo decidimos ir de viaje a Madrid en tren. Mamá nos da dinero para comprar los boletos. Llegamos a la estación y decimos "hola" al hombre que vende los boletos.</p>

      <p>El hombre nos pregunta, "Adónde van?" Juan responde, "Vamos a Madrid." Compramos dos boletos y esperamos el tren. Mientras esperamos, Juan bebe jugo y yo bebo agua.</p>

      <p>El tren llega y subimos. En el tren, vemos muchas personas. Un niño come una manzana y una mujer bebe leche. Juan y yo hablamos sobre Madrid y decimos "¡Qué interesante es viajar!"</p>

      <p>Llegamos a Madrid y tomamos un taxi al hotel. El hotel es muy grande y elegante. En nuestra habitación, hay una cama cómoda y un baño grande. Dejamos nuestras maletas y salimos a explorar la ciudad.</p>

      <p>Primero, vamos a un restaurante y comemos hamburguesas con queso y tomate. Después, visitamos un museo. El museo es muy interesante y aprendemos mucho sobre la historia de España.</p>

      <p>Al final del día, regresamos al hotel. Estamos muy cansados, pero muy felices. Decimos "buenas noches" y nos vamos a dormir, soñando con nuestras aventuras en Madrid.</p>
    </AccordionBody>

    <AccordionTitle number="7">
      Section 1, Unit 8 (Short)
    </AccordionTitle>
    <AccordionBody number="7">
      <p>Ana y Juan van al parque. Ellos tienen un perro y un gato. El perro bebe agua y el gato come una hamburguesa. Ana y Juan juegan con la pelota. La tarde es muy interesante. Al final, Ana dice "buenas noches" y van a casa. Están contentos.</p>
    </AccordionBody>

    <AccordionTitle number="8">
      Section 1, Unit 8 (Long)
    </AccordionTitle>
    <AccordionBody number="8">
      <p>Hola, me llamo Juan y soy un niño. Vivo en Madrid con mi hermana Ana. Ana es una niña muy inteligente. Nosotros tenemos un perro y un gato.</p>

      <p>Un día, Ana y yo vamos al supermercado con nuestra mamá. En el supermercado, compramos manzanas, leche, pan, queso, y jugo de naranja. Ana quiere una hamburguesa y yo quiero una ensalada.</p>

      <p>Después, vamos al museo. En el museo, vemos cosas muy interesantes. Hay un carro antiguo y una bicicleta grande. También hay pinturas de España y México.</p>

      <p>Más tarde, vamos a un restaurante. En el restaurante, bebemos agua y comemos pescado y carne. La comida es muy deliciosa.</p>

      <p>Después de comer, vamos al banco. Mi mamá necesita dinero para pagar el boleto del tren. El banco está cerrado, pero un hombre dice, "Disculpe, el banco abre a las tres."</p>

      <p>Finalmente, vamos a casa. En casa, Ana y yo jugamos con nuestro perro y gato. Es un día muy divertido.</p>

      <p>Por la noche, decimos, "Buenas noches, mamá. Buenas noches, papá." Y nos vamos a dormir.</p>
    </AccordionBody>
  </div>

);

export default Accordion;
