import React from 'react';
import Accordion from '../components/Accordion';

export const AccordionSection = ({ }) => {
  return (
    <section className="container mx-auto lg:flex px-8 pb-12">
      <div className="lg:w-2/3 mx-auto">
        <div className="">
          <h3 className="text-3xl font-semibold leading-tight mb-3">Try sample stories in Spanish 🇪🇸</h3>
          <Accordion/>
        </div>
    </div>
    </section>
  )
}
