import React from 'react';
import Accordion from '../components/Accordion';

export const FooterSection = ({ }) => {
  return (
    <section className="footer px-12">
      <div className='footer-content'>
        Made by love ❤️
      </div>
    </section>
  )
}
