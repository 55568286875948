import React from 'react';
import Layout from '../components/layout/Layout';
import { HeroSection } from '../sections/HeroSection';
import { AccordionSection } from '../sections/AccordionSection';
import faviconSrc from '../images/lingoreaders-favicon.png';
// import { ValuesSection } from '../sections/ValuesSection';
// import { HowItWorksSection } from '../sections/HowItWorksSection';
// import { CentralCTASection } from '../sections/CentralCTASection';
import { Helmet } from 'react-helmet';
import { TestimonialsSection } from '../sections/TestimonialsSection';
import { FooterSection } from '../sections/FooterSection';

const meta = {
  title: 'LingoReaders - Learn Languages from tailor-made stories that matches your level',
  desc: 'Learn Languages from tailor-made stories that matches your level!',
  keywords: 'app, languages, education, language learning, spanish, duolingo, stories, readers',
}

const mouseFlowConfig = `
  window._mfq = window._mfq || [];
  (function() {
    var mf = document.createElement("script");
    mf.type = "text/javascript"; mf.defer = true;
    mf.src = "//cdn.mouseflow.com/projects/7e873f8c-ca22-475b-b3da-dbb446035ae4.js";
    document.getElementsByTagName("head")[0].appendChild(mf);
  })();
`;

const HelmetSetup = () => {
  const isProduction = process.env.NODE_ENV === 'production';

  return (
    <Helmet
      title={meta.title}
      desc={meta.desc}
      keywords={meta.keywords}
    >
      <link rel="icon" type="image/png" href={faviconSrc} />
      <link rel="preconnect" href="https://fonts.googleapis.com"/>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
      <link href="https://fonts.googleapis.com/css2?family=Fjalla+One&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap" rel="stylesheet"/>
      <script>
        {isProduction && mouseFlowConfig}
      </script>
    </Helmet>
  )
}

const Index = () => (
  <Layout>
    <HelmetSetup/>
    <HeroSection/>
    <TestimonialsSection />
    <AccordionSection/>
    <FooterSection />
    {/* <ValuesSection/>
    <HowItWorksSection/>
    <CentralCTASection/> */}
  </Layout>
);

export default Index;
